.auth {
  display: flex;
  align-items: center;
  height: 100vh;
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  justify-content: center;
  background-color: #edf2f6;
  background-image: url(../../assets/images/hero-bg-map.png);
  background-repeat: no-repeat;
  background-size: cover;
  .brand {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
  .auth-container {
    max-width: 550px;
    width: 100%;
    margin: 24px auto;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 15%);
    border-radius: 16px;
    padding: 32px 48px;
    // background-color: green;
    .error{
      color: red;
    }
    @media (max-width: 575px) {
      padding: 16px;
    }
    h1 {
      font-size: 32px;
      font-weight: 600;
      color: #333;
      margin-bottom: 48px;
    }
    .seprator {
      position: relative;
      margin: 40px 0;
      &::before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: #e3e3e3;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 12px;
        z-index: 0;
      }
      p {
        padding: 0 8px;
        background-color: #fff;
        position: relative;
        z-index: 1;
        display: inline-block;
        font-size: 15px;
      }
    }
    p.text-center {
      margin: 16px 0 0 0;
      font-size: 15px;
    }
    p.note {
      font-size: 15px;
      text-align: left;
      margin-bottom: 24px;
    }
    .checkbox-label {
      label {
        font-weight: normal;
      }
    }
  }
}
