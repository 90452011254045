/*Navbar SCSS*/

.nav-bar-wrap {
  display: flex;
  justify-content: space-between;
  background-color: #f2f6fc;
  box-shadow: 0 0 35px 0 rgb(0 0 0 / 9%);
  position: relative;
  padding: 10px 32px 10px 282px;
  align-items: center;
  @media (max-width: 1024px) {
    padding: 16px !important;
  }
  @media (max-width: 767px) {
    padding: 8px 16px !important;
  }
  .header-left {
    display: flex;
    align-self: center;
    .collapse-menu-btn {
      display: flex;
      align-items: center;
      margin-right: 24px;
      display: none;
      @media (max-width: 1024px) {
        display: flex;
      }
      @media (max-width: 580px) {
        margin-right: 18px;
      }
      svg {
        font-size: 24px;
      }
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    @media (max-width: 992px) {
      justify-content: flex-end;
      margin: 0px 0;
    }
    .logout-icon {
      margin-right: 8px;
      @media (max-width: 580px) {
        margin-right: 0px;
      }
      button {
        svg {
          color: #eb5757;
          font-size: 23px;
          @media (max-width: 580px) {
            font-size: 21px;
          }
        }
        @media (max-width: 580px) {
          padding: 6px 6px;
        }
      }
    }
    .notification-icon {
      margin-right: 20px;
      position: relative;
      @media (max-width: 580px) {
        margin-right: 8px;
      }
      button {
        svg {
          color: #737b8b;
          font-size: 25px;
          @media (max-width: 580px) {
            font-size: 21px;
          }
        }
        @media (max-width: 580px) {
          padding: 6px 2px;
        }
      }
      .count {
        position: absolute;
        top: 2px;
        right: 9px;
        background-color: #216fed;
        border-radius: 50%;
        color: #fff;
        height: 18px;
        width: 18px;
        font-size: 11px;
        border: 1px solid #fff;
        span {
          line-height: 16px;
        }
        @media (max-width: 580px) {
          right: 4px;
          height: 16px;
          width: 16px;
        }
      }
    }
    .admin-info {
      align-items: center;
      gap: 16px;
      svg {
        height: 14px;
      }
      .admin-avatar {
        @media (max-width: 580px) {
          width: 34px;
          height: 34px;
        }
        // @media (max-width: 375px) {
        //   width: 30px;
        //   height: 30px;
        // }
        img {
          height: 48px;
          width: 48px;
          border-radius: 20px;
          @media (max-width: 580px) {
            width: 100%;
            height: 100%;
          }
        }
      }
      .admin-info {
        text-align: right;
        @media (max-width: 767px) {
          display: none;
        }
        h6 {
          margin: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          color: #273240;
          margin-bottom: 4px;
        }
        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #737b8b;
        }
      }
    }
    .user-info-btn.dropdown-toggle {
      cursor: pointer;
      &::after {
        display: none;
      }
      .admin-info {
        h6 {
          position: relative;
          display: inline-block;
          // &::after {
          //   content: ">";
          //   position: absolute;
          //   left: -18px;
          //   font-weight: 500;
          //   transform: rotate(90deg);
          // }
        }
      }
    }
    .user-info-inner-wrap {
      ul {
        li {
          a {
            display: flex;
            align-items: center;
            text-decoration: none;
            gap: 8px;
            color: #333;
            span {
            }
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
.user-profile-wrap {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  padding: 24px;
}
.update-modal {
  .modal-content {
    border: none;
    label.col-form-label {
      text-align: left;
    }
  }
}
/*sidebar SCSS*/
.sidebar-wrap {
  background-color: #f2f6fc;
  box-shadow: -1px -1px 5px 2px rgba(195, 195, 195, 38%);
  width: 250px;
  position: absolute;
  top: 0;
  height: 100vh;
  z-index: 9;
  // transition: all ease 0.3s;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  @media (max-width: 1024px) {
    left: -250px;
  }
  @media (max-width: 575px) {
    width: 225px;
  }
  .logo {
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media (max-width: 1023px) {
      justify-content: space-around;
    }
    img {
      width: 158px;
      @media (max-width: 1023px) {
        width: 55%;
      }
    }
    span {
      display: none;
      @media (max-width: 1023px) {
        display: block;
      }
    }
  }
  .sidebar-inner-wrap {
    height: calc(100vh - 86px);
    padding: 16px 16px;
    @media (max-width: 575px) {
      padding: 16px 12px;
    }
    .sidebar-menu {
      height: calc(100vh - 190px);
      overflow: auto;
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          a {
            margin-bottom: 6px;
            padding: 10px 12px 10px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 24px;
            transition: all ease 0.3s;

            @media (max-width: 575px) {
              padding: 12px 10px;
            }
            &:hover {
              background-color: #61c2d0;
            }
            .d-flex {
              .menu-inner {
                display: flex;
                align-items: center;
                gap: 12px;
                span {
                  color: #406275;
                  svg {
                    font-size: 18px;
                  }
                }
                span.small-icon {
                  svg {
                    font-size: 25px;
                    margin-left: -4px;
                  }
                }
                p {
                  font-weight: 400;
                  font-size: 15px;
                  color: #406275;
                }
              }
            }
            span {
              color: #406275;
            }
            span.chevron {
              svg {
                transition: all ease 0.3s;
              }
            }
          }
          a.active {
            background-color: #61c2d0;
          }
          .meta-menu {
            display: none;
            transition: all ease 0.3s;
            a.link-btn {
              margin: 0;
              font-size: 13px;
              text-decoration: none;
              background-color: #e1fcff;
              color: #406275;
              border-radius: none !important;
              border-radius: 4px;
              transition: all ease 0.3s;
              &:hover {
                background-color: #c0eff4;
              }
            }
            a.link-btn.active {
              background-color: #c0eff4;
            }
          }
          &:last-child {
            a {
              margin-bottom: 0;
            }
          }
        }
        li.menu-open {
          a.active.nav-link {
            border-radius: 20px 20px 0 0;
            span.chevron {
              svg {
                transform: rotate(90deg);
              }
            }
          }
          .meta-menu {
            display: block !important;
          }
        }
      }
    }
    .sidebar-footer {
      position: fixed;
      bottom: 10px;
      h5 {
        font-weight: 600;
        font-size: 13px;
        color: #333;
        margin-bottom: 0;
      }
      small {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #818181;
      }
    }
  }
}
.sidebar-wrap.sidebar-open {
  left: inherit;
}

/*Modal Popup SCSS*/
.modal {
  display: flex !important;
  align-items: center !important;
  @media (max-width: 767px) {
    display: flex !important;
    align-items: center !important;
  }
  .modal-dialog {
    max-width: 700px;
    width: 700px;
    @media (max-width: 767px) {
      width: 90%;
      margin: 0 auto;
    }
    .modal-header {
      border: none;
      .btn-close {
        // background: #e5e5e5;
        border-radius: 50%;
        color: #4f4f4f;
        font-size: 12px;
      }
    }
    .modal-body {
      text-align: center;
      .error {
        color: red;
      }
      h3 {
        font-weight: 600;
        font-size: 18px;
        color: #333333;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      p {
        font-weight: 400;
        font-size: 14px;
        margin: 0;
        color: #818181;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
    .modal-footer {
      border: none;
      display: flex;
      justify-content: center;
      padding: 48px 12px;
      @media (max-width: 767px) {
        padding: 24px 12px 32px 12px;
      }
      .btn {
        min-width: 140px;
        margin: 0 8px;
        @media (max-width: 575px) {
          min-width: 110px;
          font-size: 14px !important;
        }
        @media (max-width: 374px) {
          margin: 8px;
        }
      }
    }
  }
}
ul.nav-tabs {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(94, 132, 194, 0.06);
  border-radius: 20px;
  display: inline-flex;
  padding: 0 24px;
  margin-bottom: 32px;
  @media (max-width: 480px) {
    padding: 0 12px;
  }
  li {
    button {
      padding: 10px 24px;
      color: #737b8b;
      font-weight: 500;
      border: none !important;
      border-bottom: 4px solid transparent !important;
      font-size: 14px;
      &:hover,
      &:focus {
        border: transparent;
        color: #406275;
        border-bottom: 4px solid transparent;
      }
      @media (max-width: 992px) {
        padding: 8px 18px;
      }
      @media (max-width: 480px) {
        padding: 8px;
        font-size: 13px;
      }
    }
    button.active {
      border: none;
      color: #406275 !important;
      border-bottom: 4px solid #406275 !important;
    }
  }
}
.seller-detail-wrap {
  .seller-profile-image {
    .profile-pic {
      position: relative;
      cursor: pointer;
      width: 230px;
      height: 230px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
.seller-detail-wrap {
  .seller-profile-image {
    .profile-pic .seller-img {
      width: 230px;
      height: 230px;
      border-radius: 50%;
      border: 5px solid rgba(129, 129, 129, 0.5);
    }
  }
}
.seller-detail-wrap {
  .seller-profile-image {
    .profile-pic span {
      position: absolute;
      bottom: 18px;
      right: 20px;
      width: 35px;
      height: 35px;
      background-color: #c0c0c0;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.update-modal {
  .modal-dialog {
    max-width: 550px;
    width: 100%;
    margin: 68px auto;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1%);
    border-radius: 8px;
    padding: 32px 48px;
    @media (max-width: 767px) {
      padding: 16px;
      width: 90%;
    }
    .modal-content {
      .modal-body {
        padding: 0;
        h1 {
          font-size: 30px;
          font-weight: 600;
          color: #333;
          margin-bottom: 48px;
          @media (max-width: 767px) {
            font-size: 26px;
            margin-bottom: 28px;
          }
        }
        p {
          margin-bottom: 32px;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #333333;
        }
        .d-flex.align-items-center.col-md-12 {
          @media (max-width: 767px) {
            justify-content: space-between;
          }
        }
      }
    }
  }
}
.dropdown-item,
.form-select {
  font-size: 14px !important;
}
.main-wrap.overlay {
  &::after {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 46%);
    position: absolute;
  }
}

// update Profile
.modal.img-change-modal {
  .modal-dialog {
    background-color: #fff;
    max-width: 550px;
    width: 100%;
    margin: 24px auto;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1%);
    border-radius: 8px;
    padding: 32px 48px;
    @media (max-width: 767px) {
      padding: 16px;
      width: 90%;
    }
    .modal-content {
      border: none !important;
      h1 {
        font-size: 22px;
        font-weight: 600;
        color: #333;
        margin-bottom: 18px;
      }
      .modal-body {
        padding: 0;
        .choose-img {
        }
        > div {
          background-color: #f7fefe;
          border-radius: 10px;
          padding: 32px 0;
          div {
            border-radius: 50% !important;
            margin: 0 auto;
            label {
              height: 250px;
              width: 250px;
              border-radius: 50%;
            }
          }
        }
      }
      button {
        width: 120px;
        margin: 32px auto 0;
      }
    }
  }
}
.no-img {
  width: 45px;
  height: 45px;
  background: #dbeef1;
  color: #61c2d0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 16px;
}
.input-error {
  border: 2px solid #ff6262;
}
span.error {
  color: red;
  position: relative;
  bottom: 14px;
  font-size: 14px;
}
