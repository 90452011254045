.spinner-div{
    text-align: center;
    padding: 30px;
    color: #5fc3d0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.spinner-fallback-height{
    height: 100vh;
}