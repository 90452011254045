.listing-table.inquiry-listing-table {
  .table {
    thead {
      tr {
        th {
          padding: 16px 8px !important;
        }
        th.id-th {
          width: 5% !important;
        }
        th.specs-th {
          width: 22% !important;
        }
        th.price-th {
          width: 6% !important;
        }
        th.revenue-th {
          width: 6% !important;
        }
        th.action-th {
          width: 13% !important;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 16px 8px !important;
        }
      }
    }
  }
}
