.blog-page-content {
  .filter-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 24px 0;
    .table-filter {
      gap: 24px;
      @media (max-width: 575px) {
        gap: 18px;
      }
      .dropdown {
        button.dropdown-toggle {
          background-color: #fff !important;
          min-width: 160px;
          height: 54px !important;
          display: flex !important;
          justify-content: space-between;
          align-items: center;
          padding: 6px 24px !important;
          font-weight: 600 !important;
        }
      }
      .btn-primary {
        min-width: 160px;
        height: 54px !important;
        min-width: 140px;
        svg {
          margin-right: 8px;
        }
        @media (max-width: 767px) {
          border-radius: 14px !important;
          height: 44px !important;
        }
      }
    }
  }
  .tab-table-wrap {
    margin-top: -78px;
    @media (max-width: 767px) {
      margin-top: 0;
    }
    .listing-table.blog-listing-table {
      .table {
        thead {
          tr {
            th {
              padding: 16px 8px !important;
            }
            th.id-th {
              width: 5% !important;
            }
            th.title-th {
              width: 30% !important;
            }
            th.category-th {
              width: 10% !important;
            }
            th.author-th {
              width: 15% !important;
            }
            th.date-th {
              width: 15% !important;
            }
            th.esttime-th {
              width: 10% !important;
            }
            th.status-th {
              width: 7% !important;
            }
            th.action-th {
              width: 8% !important;
            }
          }
        }
        tbody {
          tr {
            td {
              padding: 16px 8px !important;
              @media (max-width: 1300px) {
                padding: 16px 12px !important;
              }
            }
          }
          td.status {
            p.draft {
              color: #1f2558;
            }
            p.published {
              color: #007f7f;
            }
          }
        }
      }
    }
  }
}

.create-blog-wrap {
  padding: 24px;
  background-color: #fff;
  border-radius: 20px;
  @media (max-width: 767px) {
    padding: 16px;
  }
  .create-blog {
    .left-blog-wrap {
      @media (max-width: 767px) {
        order: 2;
      }
      .left-blog {
        @media (max-width: 767px) {
          margin-top: 40px;
        }
        .dd-wrap {
          margin-bottom: 40px;
          h4 {
            font-weight: 600;
            font-size: 18px;
            color: #333333;
          }
          label {
            font-weight: 500;
            font-size: 13px;
            line-height: 14px;
            color: #818181;
          }
          .form-select {
            font-size: 14px !important;
            margin-bottom: 8px !important;
          }
          .setting-wrap {
            align-items: center;
            justify-content: space-between;
            tag {
              font-size: 12px;
              padding: 2px 10px;
              background-color: #bce1e6;
              border-radius: 5px;
            }
          }
          .add-category{
            font-size: 16px;
            font-weight: 500;
            text-decoration-line: underline;
            color: #72D1DB;
            cursor: pointer;
          }
        }
        .btn-wrap {
          gap: 16px;
          button {
            width: 100%;
            svg {
              font-size: 20px;
            }
          }
        }
      }
    }
    .right-blog-wrapper {
      .right-blog {
        @media (max-width: 767px) {
          order: 1;
        }
      }
    }
  }
}
.blog-wrap {
  padding: 24px;
  background-color: #fff;
  border-radius: 20px;
  @media (max-width: 767px) {
    padding: 16px;
  }
  .blog-detail-wrap {
    overflow: hidden;
    .main-title {
      margin-bottom: 8px;
      h5.blog-category {
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #61c2d0;
        font-weight: 700;
        font-size: 15px;
        line-height: 28px;
        margin: 0;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
      h4.blog-title {
        font-weight: 600;
        font-size: 24px;
        color: #333333;
        margin: 0;
        @media (max-width: 992px) {
          font-size: 22px;
        }
        @media (max-width: 575px) {
          font-size: 20px;
        }
      }
    }
    .blog-info {
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      gap: 40px;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        gap: 16px;
      }
      label {
        font-weight: 500;
        font-size: 14px;
        color: #818181;
        margin-right: 8px;
      }
      p {
        font-weight: 500;
        font-size: 15px;
        color: #333333;
      }
      .est-time {
        // margin: 0 40px;
      }
      .author {
        align-items: center;
        .avatar {
          height: 32px;
          width: 32px;
          border-radius: 50%;
          margin: 0 8px;
          img {
            width: 100%;
            border-radius: 50%;
          }
        }
      }
    }
    .blog-detail {
      .blog-img {
        width: 100%;
        height: 500px;
        overflow: hidden;
        margin-bottom: 56px;
        border-radius: 6px;
        img {
          width: 100%;
        }
        @media (max-width: 1366px) {
          height: inherit;
        }
      }
      .blog-content {
        padding: 0px 135px;
        @media (max-width: 1366px) {
          padding: 0px 85px;
        }
        @media (max-width: 767px) {
          padding: 0px;
        }
        p.dark {
          color: #333333;
          font-weight: 500;
          font-size: 16px;
          line-height: 32px;
          margin-bottom: 32px;
          @media (max-width: 767px) {
            line-height: 28px;
            margin-bottom: 28px;
          }
        }
        h6 {
          font-weight: 600;
          font-size: 24px;
          color: #333333;
          @media (max-width: 767px) {
            font-size: 20px;
          }
        }
        p {
          color: #818181;
          font-weight: 400;
          font-size: 14px;
        }
        .block {
          padding: 32px 0;
          @media (max-width: 767px) {
            padding: 24px 0;
          }
        }
      }
    }
  }
}
.proprty-list-wrap.article-list-wrap {
  .filter-area {
    .table-filter {
      align-items: center;
      gap: 8px;
      .btn-primary {
        height: 48px;
      }
    }
  }
}
