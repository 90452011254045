body {
  font-family: "Poppins", sans-serif !important;
  background-color: #edf2f6;
  font-size: 14px;
}
.btn-primary {
  border-radius: 20px !important;
  background-color: #61c2d0 !important;
  border: none !important;
  font-weight: 600 !important;
  color: #406275 !important;
  font-size: 16px !important;
  height: 60px;
  min-width: 100px;
  transition: all ease 0.3s !important;
  &:hover {
    background-color: #59b9c3 !important;
  }
  @media (max-width: 767px) {
    font-size: 15px !important;
    height: 52px !important;
    font-weight: 600 !important;
  }
}
.btn-secondary {
  border-radius: 20px !important;
  background-color: #f7f7f7 !important;
  border: none !important;
  font-weight: 600 !important;
  color: #818181 !important;
  font-size: 16px !important;
  height: 60px;
  min-width: 100px;
  transition: all ease 0.3s !important;
  &:hover {
    background-color: #eae7e7 !important;
  }
  @media (max-width: 767px) {
    font-size: 15px !important;
    height: 52px !important;
    font-weight: 600 !important;
  }
}
.btn-alert {
  background-color: #dc3545 !important;
  color: #fff !important;
  &:hover {
    background-color: #c61c2d !important;
  }
}
.form-control,
.form-select,
.input-group {
  height: 48px;
  margin-bottom: 16px;
  font-size: 14px !important;
  @media (max-width: 767px) {
    height: 46px;
  }
}
.input-group {
  .input-group-text {
    background-color: transparent;
    border-right: none;
    padding-right: 0;
  }
  .form-control {
    border-left: none;
  }
}
label {
  font-weight: 700;
  font-size: 14px !important;
  color: #333;
  padding-bottom: 0px !important;
}
label span.astrik {
  line-height: inherit;
  color: #ea6565 !important;
  font-size: 16px !important;
}
.link-btn {
  background-color: transparent;
  border: none;
  font-size: 14px;
  color: #61c2d0;
  text-decoration: underline;
}
* {
  &::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    background-color: #dddddd;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(210, 210, 210, 0.519);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(210, 210, 210, 0.519);
    background-color: rgba(92, 92, 92, 0.474);
  }
}
