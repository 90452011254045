.listing-table.customer-table {
  .customer-table-inner {
    .table-row {
      padding: 12px 24px;
      background-color: #fff;
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 4px 20px rgba(94, 132, 194, 0.06);
      margin-bottom: 10px;
      transition: all ease 0.3s;
      @media (max-width: 1300px) {
        padding: 16px 16px;
        flex-wrap: wrap;
      }
      &:last-child {
        margin-bottom: 16px;
      }
      &:hover {
        box-shadow: 0px 10px 49px rgba(94, 132, 194, 0.31);
      }
      .inner-wrap {
        small {
          color: #333333;
          font-size: 13px;
          font-weight: 400;
        }
        h5 {
          margin: 0;
          font-weight: 600;
          font-size: 16px;
          color: #273240;
        }
        label {
          font-weight: 400;
          font-size: 13px !important;
          color: #737b8b;
        }
        p {
          color: #273240;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }
      }
      > div {
        padding: 0 8px;
      }
      .avatar {
        width: 6%;
        @media (max-width: 1290px) {
          width: 10%;
        }
        @media (max-width: 580px) {
          width: 20%;
        }
        @media (max-width: 360px) {
          width: 25%;
        }
        > div {
          width: 45px;
          height: 45px;
          border-radius: 16px;
          img {
            width: 100%;
          }
        }
      }
      .main-info {
        width: 30%;
        @media (max-width: 1290px) {
          width: 30%;
        }
        @media (max-width: 767px) {
          width: 45%;
        }
        @media (max-width: 580px) {
          width: 80%;
        }
        @media (max-width: 360px) {
          width: 75%;
        }
      }
      .location {
        width: 22%;
        @media (max-width: 1290px) {
          width: 30%;
        }
        @media (max-width: 767px) {
          width: 45%;
        }
        @media (max-width: 580px) {
          width: 65%;
          margin-top: 16px;
        }
        @media (max-width: 440px) {
          width: 100%;
        }
      }
      .phone {
        width: 12%;
        @media (max-width: 1366px) {
          // width: 33%;
        }
        @media (max-width: 1290px) {
          width: 20%;
        }
        @media (max-width: 767px) {
          width: 25%;
        }
        @media (max-width: 580px) {
          width: 35%;
          margin-top: 16px;
        }
        @media (max-width: 440px) {
          width: 100%;
        }
      }
      .email {
        width: 30%;
        @media (max-width: 1290px) {
          width: 33%;
        }
        @media (max-width: 767px) {
          width: 35%;
        }
        @media (max-width: 580px) {
          width: 65%;
          margin-top: 16px;
        }
        @media (max-width: 440px) {
          width: 100%;
        }
      }
      .inquiry-history {
        width: 18%;
        position: relative;
        @media (max-width: 1366px) {
          // width: 20%;
        }
        @media (max-width: 1290px) {
          width: 33%;
        }
        @media (max-width: 767px) {
          width: 26%;
        }
        @media (max-width: 580px) {
          width: 50%;
        }
        @media (max-width: 440px) {
          width: 80%;
        }
        .btn-link {
          font-weight: 500;
          font-size: 14px;
          color: #61c2d0;
          display: flex;
          padding: 0;
          align-items: center;
          gap: 8px;
          span {
            font-size: 20px;
            svg {
              transition: all ease 0.3ms;
              path {
                stroke: #61c2d0;
              }
            }
          }
        }
        .btn-link.active {
          color: #406275;
        }
        .inquiry-history-block {
          background: #ffffff;
          box-shadow: 0px 14px 41px rgba(0, 0, 0, 0.24);
          border-radius: 20px;
          padding: 16px;
          width: 400px;
          position: absolute;
          right: 0;
          z-index: 9;
          @media (max-width: 580px) {
            left: 0;
          }
          @media (max-width: 480px) {
            width: 300px;
            padding: 12px;
          }
          @media (max-width: 374px) {
            left: -23px;
          }
          .inquiry-history-row {
            gap: 16px;
            margin-bottom: 24px;
            @media (max-width: 480px) {
              gap: 8px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            .property-img {
              width: 25%;
              @media (max-width: 480px) {
                width: 20%;
              }
              .property-img-inner {
                height: 85px;
                width: 85px;
                border-radius: 20px;
                overflow: hidden;
                @media (max-width: 480px) {
                  height: 58px;
                  width: 58px;
                  border-radius: 14px;
                }
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .inquiry-info {
              width: 84%;
              @media (max-width: 480px) {
                width: 80%;
              }
              p.property-name {
                font-weight: 500;
                font-size: 15px;
                color: #000000;
                margin: 0;
                line-height: 18px;
                @media (max-width: 480px) {
                  font-size: 12px;
                }
              }
              p.date {
                font-weight: 400;
                font-size: 13px;
                color: #969ba0;
                margin: 0;
                @media (max-width: 480px) {
                  font-size: 12px;
                }
              }
              .agent-info {
                margin-top: 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .inner-wrap {
                  align-items: center;
                  gap: 8px;
                  .agent-img {
                    border-radius: 50%;
                    width: 28px;
                    height: 28px;
                    overflow: hidden;
                    @media (max-width: 480px) {
                      width: 25px;
                      height: 25px;
                    }
                    img {
                      width: 100%;
                    }
                  }
                  p.agent-name {
                    font-weight: 500;
                    font-size: 13px;
                    color: #44444f;
                    margin: 0;
                    @media (max-width: 480px) {
                      font-size: 12px;
                    }
                  }
                }
                tag {
                  font-size: 12px;
                  padding: 2px 10px;
                  background-color: #bce1e6;
                  border-radius: 5px;
                  @media (max-width: 480px) {
                    font-size: 11px;
                  }
                }
              }
            }
          }
        }
      }
      .dd-action {
        width: 2%;
        @media (max-width: 580px) {
          width: 10%;
        }
        @media (max-width: 440px) {
          width: 20%;
        }
        .dropdown {
          display: flex;
          justify-content: flex-end;
          button.dropdown-toggle {
            background-color: #fff !important;
            min-width: inherit;
            display: flex !important;
            justify-content: space-between;
            align-items: center;
            height: inherit;
            margin: 0;
            padding: 6px 4px;
            &:after {
              display: none;
            }
            svg {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}
.proprty-list-wrap.admin-list-wrap {
  .filter-area {
    .table-filter {
      align-items: center;
      gap: 8px;
      .btn-primary {
        height: 48px;
        gap: 5px;
        display: flex;
        align-items: center;
      }
    }
  }
}
