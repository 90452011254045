/*Content Body Common SCSS*/
.content-body {
  padding-left: 250px;
  height: calc(100vh - 69px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #edf2f6;
  @media (max-width: 1024px) {
    padding-left: 0;
  }
  .content-body-inner {
    padding: 32px;
    @media (max-width: 1024px) {
      padding: 24px 16px;
    }
    .page-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
      h1 {
        font-weight: 600;
        font-size: 22px;
        line-height: 32px;
        color: #273240;
        margin: 0;
        @media (max-width: 575px) {
          margin-bottom: 8px;
          font-size: 22px;
        }
      }
      ol.breadcrumb {
        margin: 0;
        align-items: center;
        span {
          color: #737b8b;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
        }
        span.seprator {
          margin: 0 8px;
        }
        a.link-btn {
          color: #61c2d0;
          font-weight: 500;
        }
      }
    }
  }
}
.green-color {
  color: #007f7f;
}
.red-color {
  color: #f68f7e;
}

/*Content Body Dashboard SCSS*/
.content-body {
  .page-content {
    .main-analytics-card {
      margin-bottom: 24px;
      .card-wrapper {
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        @media (max-width: 992px) {
          margin-bottom: 12px;
        }
        .card-info {
          width: 65%;
          small {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #737b8b;
            margin-bottom: 6px;
            display: flex;
            @media (max-width: 575px) {
              font-size: 14px;
            }
          }
          h2 {
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            color: #202020;
            margin-bottom: 0;
            @media (max-width: 575px) {
              font-size: 18px;
            }
          }
          .d-flex {
            align-items: center;
            gap: 8px;
            span {
              svg {
                font-size: 20px;
              }
            }
          }
        }
        .card-graph {
          width: 35%;
          text-align: right;
          span {
            svg {
              font-size: 22px;
              color: #406275;
            }
          }
        }
      }
      .card-wrapper.property-info-card {
        align-items: flex-start;
        .d-flex {
          align-items: flex-end;
          gap: 3px;
          span {
            color: #737b8b;
            font-size: 13px;
          }
        }
        .link-btn {
          font-weight: 500 !important;
          font-size: 14px !important;
          color: #61c2d0 !important;
        }
      }
    }

    .overview-wrap {
      .count-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 1090px) {
          flex-wrap: wrap;
          gap: 16px;
        }
        .count {
          display: flex;
          align-items: center;
          gap: 16px;
          @media (max-width: 1024px) {
            gap: 8px;
          }
          span.icon {
            box-shadow: 0px 10px 49px rgba(94, 132, 194, 0.05);
            border-radius: 16px;
            width: 54px;
            height: 54px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 992px) {
              width: 44px;
              height: 44px;
            }
            svg {
              color: #fff;
              font-size: 28px;
              gap: 16px;
              @media (max-width: 992px) {
                font-size: 22px;
              }
            }
          }
          .data {
            small {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #737b8b;
              @media (max-width: 1024px) {
                font-size: 13px;
              }
            }
            p {
              font-weight: 600;
              font-size: 18px;
              line-height: 20px;
              color: #273240;
              @media (max-width: 1024px) {
                font-size: 15px;
              }
            }
          }
        }
        .count.total-view {
          span.icon {
            background: linear-gradient(
              133.21deg,
              #61c2d0 33.75%,
              #edfdff 116.05%
            );
          }
        }
        .count.new-list {
          span.icon {
            background: linear-gradient(
              133.21deg,
              #007f7f 17.07%,
              rgba(58, 201, 115, 0.12) 116.05%
            );
          }
        }
        .performance {
          display: flex;
          align-items: center;
          gap: 8px;
          .data {
            text-align: right;
            p {
              font-weight: 600;
              font-size: 18px;
              line-height: 20px;
              @media (max-width: 1024px) {
                font-size: 15px;
              }
            }
            small {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #737b8b;
              @media (max-width: 1024px) {
                font-size: 13px;
              }
            }
          }
          .icon {
            svg {
              font-size: 20px;
            }
          }
        }
      }
      .graph-wrap {
        min-height: 280px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        .apexcharts-canvas {
          width: 500px;
          @media (max-width: 1024px) {
            width: 300px;
          }
          .apx-legend-position-top {
            top: 0 !important;
          }
        }
      }
    }
    .card-wrapper {
      background-color: #fff;
      box-shadow: 0px 4px 20px rgba(94, 132, 194, 0.06);
      border-radius: 20px;
      padding: 24px;
      margin-bottom: 24px;
      @media (max-width: 1024px) {
        padding: 24px 16px;
      }
      .title-wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        h3 {
          margin: 0;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          color: #273240;
        }
      }
    }
    .progress {
      height: 10px;
      background: #d7f8fc;
      border-radius: 5px;
      .progress-bar {
        background: #61c2d0;
        border-radius: 5px;
      }
    }

    .recent-customer {
      .list-wrap {
        .list-row {
          display: flex;
          gap: 16px;
          align-items: center;
          margin-bottom: 14px;
          &:last-child {
            margin-bottom: 0;
          }
          .avatar {
            width: 48px;
            height: 48px;
            background-color: #f1f1f1;
            border-radius: 14px;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
          .list-info {
            h4 {
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              color: #202020;
              margin-bottom: 6px;
            }
            p {
              margin: 0;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              color: #737b8b;
            }
          }
        }
      }
    }
    .agent-customer {
      height: 140px;
    }
    .property-analytics,
    .blog-analytics {
      .progress-inner-wrap,
      .blog-inner-wrap {
        margin-bottom: 32px;
        &:last-child {
          margin-bottom: 0px;
        }
        .title {
          justify-content: space-between;
          margin-bottom: 8px;
          h4 {
            font-weight: 500;
            font-size: 15px;
            line-height: 16px;
            color: #000000;
            margin: 0;
          }
          p {
            color: rgba(0, 0, 0, 0.5);
            font-weight: 400;
            font-size: 13px;
            margin: 0;
          }
        }
      }
    }
  }
}
