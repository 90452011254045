.content-body {
  .page-content {
    .analytics {
      display: flex;

      @media (max-width: 1024px) {
        flex-wrap: wrap;
        gap: 16px;
      }

      .left-wrap {
        display: flex;
        gap: 40px;

        @media (max-width: 992px) {
          gap: 24px;
          flex-wrap: wrap;
        }

        @media (max-width: 767px) {
          gap: 8px;
        }

        .total-wrap {
          display: flex;
          align-items: center;
          gap: 16px;
          background-color: #fff;
          box-shadow: 0px 4px 20px rgba(94, 132, 194, 0.06);
          border-radius: 20px;
          padding: 10px 16px;

          @media (max-width: 992px) {
            padding: 16px;
          }

          @media (max-width: 580px) {
            width: 100%;
          }

          span.icon {
            box-shadow: 0px 10px 49px rgba(94, 132, 194, 0.05);
            border-radius: 18px;
            width: 54px;
            height: 54px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(114, 209, 219, 0.36);

            @media (max-width: 767px) {
              width: 44px;
              height: 44px;
            }

            svg {
              color: #61c2d0;
              font-size: 23px;
            }
          }

          .data {
            small {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #737b8b;
            }

            p {
              font-weight: 600;
              font-size: 18px;
              line-height: 24px;
              color: #273240;

              @media (max-width: 767px) {
                font-size: 20px;
              }
            }
          }
        }
      }

      .right-analytics {
        margin-left: 80px;

        @media (max-width: 1024px) {
          margin-left: 32px;
        }

        @media (max-width: 1024px) {
          margin-left: 0px;
        }

        .inner-card {
          display: flex;
          align-items: center;
          background-color: #fff;
          box-shadow: 0px 4px 20px rgba(94, 132, 194, 0.06);
          border-radius: 20px;
          padding: 10px 16px;

          @media (max-width: 767px) {
            padding: 16px;
          }

          .seprator {
            height: 56px;
            width: 2px;
            background-color: #edeff1;
          }

          .left,
          .right {
            padding: 0 32px;

            @media (max-width: 992px) {
              padding: 0 16px;
            }

            h3 {
              color: #273240;
              margin: 0;
              font-weight: 600;
              font-size: 18px;
              line-height: 26px;
            }

            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              color: #737b8b;
            }
          }
        }
      }
    }

    .filter-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 16px 0;

      @media (max-width: 580px) {
        flex-wrap: wrap;
        gap: 8px;
      }

      .table-search-bar {
        @media (max-width: 580px) {
          width: 100%;
        }

        .search-field-wrap {
          .searchbox-wrap.input-group {
            margin-bottom: 0;
            max-width: 400px;
            width: 380px;

            @media (max-width: 580px) {
              max-width: 100%;
              width: 100%;
            }

            input {
              margin: 0;
              border: none;
              border-radius: 45px 0 0 45px;
              padding-left: 24px;

              @media (max-width: 767px) {
                font-size: 14px;
              }

              @media (max-width: 375px) {
                font-size: 13px;
              }

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }

            button {
              border: none;
              background-color: #fff;
              border-radius: 0 45px 45px 0;
              padding-right: 24px;
              z-index: 0;
            }
          }
        }
      }

      .table-filter {
        .dropdown {
          button.dropdown-toggle {
            background-color: #fff !important;
            min-width: 160px;
            height: 48px !important;
            display: flex !important;
            justify-content: space-between;
            align-items: center;
            padding: 6px 24px !important;
            font-weight: 600 !important;
            font-size: 14px;

            @media (max-width: 767px) {
              border-radius: 14px !important;
              height: 44px !important;
              min-width: 122px;
              padding: 6px 18px !important;
            }
          }
        }
      }
    }

    .listing-table {
      .table {
        background: #ffffff;
        border-radius: 20px;
        overflow: hidden;
        border-color: #f2f6fc;

        thead {
          tr {
            th {
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              color: #273240;
              padding: 20px 4px;

              @media (max-width: 992px) {
                padding: 12px 14px;
              }

              .checkbox-label.form-check {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            th.id-th {
              width: 3%;
              padding: 20px 4px 20px 10px;
            }

            th.photo-th {
              width: 5%;
            }

            th.property-th {
              width: 8%;
            }

            th.location-th {
              width: 13%;
            }

            th.specs-th {
              width: 25%;
            }

            th.price-th {
              width: 8%;
            }

            th.revenue-th {
              width: 8%;
            }

            th.agent-th {
              width: 10%;
            }

            th.status-th {
              width: 8%;
            }

            th.action-th {
              width: 8%;
            }

            th.empty-th {}
          }
        }

        tbody {
          tr {
            transition: all ease 0.3s;

            td {
              font-weight: 400;
              font-size: 13px;
              color: #273240;
              padding: 16px 4px;
              vertical-align: middle;

              @media (max-width: 992px) {
                padding: 12px 14px !important;
              }

              .link-btn {
                color: #273240;
                font-size: 13px;
              }
            }

            td {
              &:first-child {
                padding: 20px 4px 20px 10px;
              }
            }

            td.photo {
              .photo-wrap {
                width: 45px;
                height: 45px;
                border-radius: 10px;
                overflow: hidden;

                img {
                  width: 45px;
                  height: 45px;
                }
              }
            }

            td.specs {
              .spec-inner-wrap {
                &:nth-child(2) {
                  margin: 0 8px;
                }

                span {
                  svg {
                    color: #61c2d0;
                    margin-right: 4px;
                    font-size: 22px;
                  }
                }

                p {}
              }
            }

            td.status {
              p {
                font-weight: 500;
              }

              p.negotiation {
                color: #1f2558;
              }

              p.pending {
                color: #f68f7e;
              }

              p.approved {
                color: #007f7f;
              }
            }

            td.dd-action {
              .dropdown {
                button.dropdown-toggle {
                  background-color: transparent !important;
                  min-width: inherit;
                  display: flex !important;
                  justify-content: space-between;
                  align-items: center;
                  height: inherit;
                  margin: 0;
                  padding: 6px 4px;
                  width: 100%;
                  justify-content: flex-end;

                  &:after {
                    display: none;
                  }

                  svg {
                    font-size: 22px;
                  }
                }
              }
            }

            td.action.view-inquiry {
              .btn-link {
                font-weight: 500;
                font-size: 14px;
                color: #61c2d0;
                display: flex;
                padding: 0;
                align-items: center;
                gap: 8px;

                span {
                  font-size: 20px;

                  svg {
                    path {
                      stroke: #61c2d0;
                    }
                  }
                }
              }
            }

            &:hover {
              box-shadow: 0px 10px 49px rgba(94, 132, 194, 0.31);
            }
          }
        }
      }
    }

    .listing-table-pagination {
      margin-left: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 32px;
      flex-wrap: wrap;

      .pagination-info {
        @media (max-width: 768px) {
          display: flex;
          width: 100%;
        }

        p {
          margin: 0;
          color: #273240;
          font-weight: 500;
          font-size: 14px;
        }
      }

      ul.pagination {
        margin-right: 80px;
        margin-bottom: 0;

        @media (max-width: 768px) {
          margin: 16px 0 0 90px !important;
        }

        @media (max-width: 575px) {
          margin: 16px 0 0 68px !important;
        }

        @media (max-width: 350px) {
          margin: 16px 0 0 52px !important;
        }

        li {
          margin: 0 6px;

          @media (max-width: 575px) {
            margin: 0 2px;
          }

          a.page-link {
            border: none;
            background-color: transparent;
            color: #406275;

            @media (max-width: 575px) {
              padding: 6px 8px;
            }
          }
        }

        li.active {
          span.page-link {
            background-color: transparent;
            color: #61c2d0;
            border: none;
          }
        }

        li.prev-btn,
        li.next-btn {
          position: relative;

          a {
            span {
              display: none;
            }
          }
        }

        li.prev-btn {
          a {
            &:before {
              content: "Previous";
              position: absolute;
              top: -2px;
              margin-left: 0;
              left: -90px;
              border-radius: 20px !important;
              background-color: #61c2d0 !important;
              border: none !important;
              font-weight: 600 !important;
              color: #fff !important;
              font-size: 14px !important;
              height: 40px;
              min-width: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              @media (max-width: 575px) {
                min-width: 75px;
                font-size: 12px !important;
                font-weight: 400 !important;
                left: -70px;
              }

              @media (max-width: 350px) {
                min-width: 64px;
                font-size: 10px !important;
                left: -54px;
              }
            }
          }
        }

        li.next-btn {
          a {
            &:after {
              content: "Next";
              position: absolute;
              top: -2px;
              margin-left: 0;
              left: 1 0px;
              border-radius: 20px !important;
              background-color: #61c2d0 !important;
              border: none !important;
              color: #fff !important;
              font-size: 14px !important;
              font-size: 16px !important;
              height: 40px;
              min-width: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              @media (max-width: 575px) {
                min-width: 75px;
                font-size: 12px !important;
                font-weight: 400 !important;
              }

              @media (max-width: 350px) {
                min-width: 64px;
                font-size: 10px !important;
              }
            }
          }
        }
      }
    }

    // .rdt_Table {
    //   direction: rtl
    // }

    // .rdt_TableCell,
    // .rdt_TableCol {
    //   direction: ltr
    // }

    .custom-table-comp {
      .rdt_TableCol>div>div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .rdt_TableHeadRow {
        background-color: #f0f0f0;
        font-weight: bold;
      }

      .rdt_TableBody {
        .rdt_TableRow:nth-of-type(even) {
          background-color: #fbfcfd;
        }

        .rdt_TableRow {
          .rdt_TableCell {
            .ctm-table-cell {
              margin: 0;
              width: 100% !important;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .header-cell {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .arrow-down {
          transform: rotateZ(90deg);
        }

        .btn-arrow {
          cursor: pointer;
        }
      }
    }

    .custom-sub-table-comp {
      .rdt_TableRow {
        background-color: #ebf9fa !important;

        .ctm-table-input-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .ctm-table-input {
            width: 30%;
            outline: none;
            border: none;
            background-color: transparent;
            text-align: center;
            border: 1px solid rgb(229, 229, 229);
            padding-block: 1px;
          }

          .ctm-table-input:focus {
            background-color: white;
            border-radius: 2px;
          }

          .ctm-table-input::-webkit-outer-spin-button,
          .ctm-table-input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }

      .rdt_TableRow>div:first-child {
        background-color: #f3fbfc !important;

        div {
          padding-left: 15px;
        }
      }
    }

  }
}

.form-switch {
  margin: 0;
  display: flex !important;
  align-items: center !important;

  .form-check-input {
    height: 20px;
    width: 36px !important;
    margin-right: 4px;

    &:focus {
      box-shadow: none;
    }
  }

  label {
    font-weight: 500;
    font-size: 13px;
    color: #273240;

    @media (max-width: 575px) {
      white-space: nowrap;
    }
  }

  input[value="true"] {
    background-color: #40c040;
    border-color: #40c040;
  }
}

// Property-detail SCSS
.page-content {
  .property-detail-wrap {
    padding: 24px;
    background-color: #fff;
    border-radius: 20px;

    @media (max-width: 767px) {
      padding: 16px;
    }

    .header {
      .header-inner {
        position: relative;

        .property-img {
          position: relative;
          border-radius: 20px;
          overflow: hidden;
          height: 470px;

          @media (max-width: 1550px) {
            height: 360px;
          }

          @media (max-width: 1150px) {
            height: 280px;
            border-radius: 13px;
          }

          @media (max-width: 575px) {
            height: 230px;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(180deg,
                rgba(0, 0, 0, 0) 58.2%,
                rgb(0, 0, 0) 100%);
          }

          img {
            width: 100%;

            @media (max-width: 600px) {
              height: 100%;
            }
          }
        }

        .status {
          position: absolute;
          color: #fff;
          font-weight: 500;
          font-size: 14px;
          top: 0;
          left: 0;
          background: #406275;
          border-radius: 20px 0px;
          padding: 8px 16px;

          @media (max-width: 1150px) {
            border-radius: 13px 0;
            font-size: 12px;
          }
        }

        .property-basic-info {
          position: absolute;
          width: 100%;
          bottom: 24px;

          @media (max-width: 767px) {
            bottom: 15px;
          }

          .basic-info {
            @media (max-width: 1150px) {
              padding: 0 24px;
            }

            @media (max-width: 575px) {
              padding: 0 8px;
            }

            h1 {
              color: #fff;
              margin: 0;
              font-weight: 600;
              font-size: 24px;
              text-transform: capitalize;

              @media (max-width: 1150px) {
                font-size: 24px;
              }

              @media (max-width: 575px) {
                font-size: 18px;
              }
            }

            h4 {
              color: #fff;
              margin: 8px 0;
              font-weight: 500;
              font-size: 18px;

              @media (max-width: 1150px) {
                font-size: 20px;
                margin-top: 0;
              }

              @media (max-width: 575px) {
                font-size: 16px;
                font-weight: 500;
              }
            }

            .specs {
              margin-top: 16px;

              .content {
                @media (max-width: 1150px) {
                  flex-wrap: wrap;
                  gap: 6px;
                }

                @media (max-width: 575px) {
                  gap: 0px;
                }

                .icon-wrap {
                  align-items: center;
                  margin-right: 24px;

                  @media (max-width: 1150px) {
                    margin-right: 16px;
                  }

                  .icon {
                    color: #61c2d0;
                    margin-right: 8px;

                    svg {
                      width: 20px;

                      @media (max-width: 575px) {
                        width: 16px;
                      }
                    }
                  }

                  p {
                    margin: 0;
                    color: #61c2d0;
                    font-size: 13px;
                    margin: 0;

                    @media (max-width: 575px) {
                      font-size: 11px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .property-meta-data {
      h2 {
        font-weight: 600;
        font-size: 18px;
        color: #333333;
        margin-bottom: 16px;
        margin-top: 24px;

        @media (max-width: 767px) {
          font-size: 18px;
          margin-bottom: px;
          margin-top: 16px;
        }
      }

      .left-side-data {
        margin: -150px 32px 0 32px;
        position: relative;

        @media (max-width: 1150px) {
          margin: -130px 12px 0 12px;
        }

        @media (max-width: 767px) {
          margin: 30px 0px 0 0px;
        }

        .seller-info {
          .img-wrap {
            border-radius: 20px;
            overflow: hidden;
            margin-bottom: 24px;
            border: 2px solid #ffffff;
            box-shadow: 0px 10px 49px rgba(94, 132, 194, 0.31);
            min-height: 200px;
            background-color: #f2f6fc;

            @media (max-width: 1150px) {
              border: 4px solid #ffffff;
            }

            @media (max-width: 767px) {
              width: 340px;
              margin-bottom: 16px;
            }

            @media (max-width: 480px) {
              width: 70%;
            }

            img {
              width: 100%;
              height: auto;
            }
          }

          .info {
            .name {
              margin-bottom: 6px;
              display: flex;
              align-items: center;

              h3 {
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
                color: #333333;
                margin: 0;

                @media (max-width: 767px) {
                  font-size: 20px;
                }
              }

              svg {
                margin-left: 8px;
                color: #406275;
                height: 20px;
                width: 20px;
              }
            }

            p {
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              color: #818181;
            }
          }

          .contact-info {
            .d-flex {
              align-items: center;
              margin-bottom: 8px;

              svg {
                color: #61c2d0;
                margin-right: 8px;
                height: 22px;
                width: 22px;
              }

              p {
                margin: 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 26px;
                color: #333;
              }
            }
          }

          .inquiry-form {
            .btn-primary {
              width: 100%;
            }
          }
        }

        .price-wrap {
          .price-inner-wrap {
            background: #d7f8fc;
            border-radius: 20px;
            padding: 8px 16px;

            @media (max-width: 767px) {
              padding: 8px 16px;
            }

            ul {
              margin: 0;
              padding: 0;
              list-style-type: none;

              li {
                .info-inner {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin: 12px 0;

                  p {
                    font-weight: 500;
                    font-size: 14px;
                    color: #406275;
                    margin: 0;
                  }

                  span {
                    font-weight: 600;
                    font-size: 15px;
                    color: #406275;

                    @media (max-width: 767px) {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }

        .detail-wrap {
          .detail-inner-wrap {
            ul {
              margin: 0;
              padding: 0;
              list-style-type: none;

              li {
                .info-inner {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin: 12px 0;

                  @media (max-width: 767px) {
                    margin: 6px 0;
                  }

                  p {
                    margin: 0;
                    font-weight: 500;
                    font-size: 15px;

                    @media (max-width: 767px) {
                      font-size: 14px;
                    }
                  }

                  span {
                    color: #818181;
                    font-weight: 400;
                    font-size: 15px;

                    @media (max-width: 767px) {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .description {
        .d-flex.desc-title {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 40px;
          margin-bottom: 16px;
          margin-top: 24px;

          @media (max-width: 360px) {
            gap: 8px;
          }

          h2 {
            margin: 0;
          }

          .form-check.form-switch {
            label {
              margin-top: 2px;
            }
          }
        }

        .content {
          p {
            color: #818181;
            font-weight: 400;
            font-size: 16px;

            @media (max-width: 767px) {
              font-size: 14px;
            }
          }
        }
      }

      .photo {
        .img-gallary {
          display: flex;
          margin: 0 -16px;

          .img-inner-wrap {
            overflow: hidden;
            width: 33.33%;
            padding: 0 16px;

            @media (max-width: 767px) {
              padding: 0 6px;
            }

            img {
              border-radius: 18px;
              width: 100%;

              @media (max-width: 767px) {
                border-radius: 10px;
              }
            }
          }
        }
      }

      .amenities {
        .amenities-wrap {
          margin-bottom: 4px;

          svg {
            color: #61c2d0;
            margin-right: 16px;
            width: 22px;
            height: 23px;

            @media (max-width: 1200px) {
              margin-right: 8px;
            }
          }

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            color: #333333;

            @media (max-width: 1024px) {
              font-size: 14px;
            }
          }
        }
      }

      .content {
        margin-bottom: 40px;

        .inner-wrap {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;

          @media (max-width: 767px) {
            margin-bottom: 2px;
          }

          label {
            font-weight: 500;
            font-size: 14px !important;
            line-height: 28px;
            color: #333333;

            @media (max-width: 360px) {
              font-size: 13px !important;
            }
          }

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            color: #818181;

            @media (max-width: 360px) {
              font-size: 13px !important;
            }
          }
        }
      }

      .Location {
        .address {
          align-items: center;
          margin-bottom: 24px;

          @media (max-width: 575px) {
            align-items: flex-start;
          }

          svg {
            width: 25px;

            @media (max-width: 575px) {
              width: 22px;
            }
          }

          p {
            margin: 0 0 0 6px;

            @media (max-width: 575px) {
              font-size: 15px;
            }
          }
        }

        .add-map {
          height: 300px;
          background-color: #ecf9f9;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;

          svg.icon-pin {
            width: 88px;
          }
        }
      }

      .inquiry {
        .content {
          .inquiry-detail-row {
            justify-content: space-between;
            margin: 40px 0;

            @media (max-width: 1366px) {
              flex-wrap: wrap;
            }

            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-bottom: 0;

              .basic-info {
                &:before {
                  display: none;
                }
              }
            }

            .basic-info {
              align-items: center;
              width: 25%;
              position: relative;

              @media (max-width: 1200px) {
                width: 33%;
              }

              @media (max-width: 575px) {
                width: 100% !important;
              }

              .avatar {
                position: relative;
                width: 42px;
                height: 42px;
                background-color: #f1f1f1;
                border-radius: 10px;
                overflow: hidden;

                img {
                  width: 100%;
                }
              }

              .info {
                margin-left: 8px;

                p {
                  color: #273240;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 16px;
                }

                small {
                  font-weight: 400;
                  font-size: 14px;
                  color: #737b8b;

                  @media (max-width: 767px) {
                    font-size: 12px;
                  }
                }
              }

              &:before {
                content: "";
                width: 2px;
                height: 100%;
                position: absolute;
                left: 25px;
                top: 40px;
                border: 1px dashed #61c2d0;
                z-index: 0;

                @media (max-width: 1200px) {
                  display: none;
                }
              }
            }

            .other-info-wrap {
              small {
                font-weight: 400;
                font-size: 14px;
                color: #737b8b;
              }

              p {
                color: #273240;
                font-weight: 600;
                font-size: 14px;
              }
            }

            .other-info-wrap.phone {
              width: 19%;

              @media (max-width: 1200px) {
                width: 28% !important;
              }

              @media (max-width: 575px) {
                width: 100% !important;
                margin: 12px 0;
              }
            }

            .other-info-wrap.email {
              width: 31%;

              @media (max-width: 1200px) {
                width: 38% !important;
              }

              @media (max-width: 575px) {
                width: 100% !important;
              }
            }

            .seller-info-wrap {
              display: flex;
              align-items: center;
              gap: 16px;
              width: 25%;

              @media (max-width: 1300px) {
                width: 100%;
                margin: 25px 0 0 0px;
                z-index: 1;
                justify-content: flex-end;
              }

              .d-flex {
                align-items: center;

                .avatar {
                  margin-right: 8px;

                  img {
                    width: 33px;
                    height: 33px;
                    border-radius: 50%;
                  }
                }

                p {
                  margin: 0;
                  color: #44444f;
                  font-weight: 600;
                  font-size: 13px;

                  @media (max-width: 767px) {
                    font-size: 12px;
                  }
                }
              }

              tag {
                font-size: 12px;
                padding: 2px 10px;
                background-color: #bce1e6;
                border-radius: 5px;
              }
            }
          }
        }
      }

      .mb-5 {
        @media (max-width: 600px) {
          margin-bottom: 32px !important;
        }
      }
    }
  }
}

// Property-list tab SCSS
.page-content.proprty-list-wrap {
  .filter-area {
    justify-content: flex-end;
    gap: 8px;
  }

  .tab-table-wrap {
    margin-top: -60px;

    @media (max-width: 1390px) {
      margin-top: inherit;
    }
  }
}