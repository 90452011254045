.blog-page-content {
  .filter-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 24px 0;
    .table-filter {
      gap: 24px;
      @media (max-width: 575px) {
        gap: 18px;
      }
      .dropdown {
        button.dropdown-toggle {
          background-color: #fff !important;
          min-width: 160px;
          height: 54px !important;
          display: flex !important;
          justify-content: space-between;
          align-items: center;
          padding: 6px 24px !important;
          font-weight: 600 !important;
        }
      }
      .btn-primary {
        min-width: 160px;
        height: 48px !important;
        min-width: 140px;
        font-size: 15px !important;
        svg {
          margin-right: 8px;
        }
        @media (max-width: 767px) {
          border-radius: 14px !important;
          height: 44px !important;
        }
      }
    }
  }
  .tab-table-wrap {
    margin-top: -71px;
    @media (max-width: 767px) {
      margin-top: 0;
    }
    .listing-table.blog-listing-table {
      .table {
        thead {
          tr {
            th {
              padding: 16px 8px !important;
            }
            th.id-th {
              width: 5% !important;
            }
            th.title-th {
              width: 30% !important;
            }
            th.category-th {
              width: 10% !important;
            }
            th.author-th {
              width: 15% !important;
            }
            th.date-th {
              width: 15% !important;
            }
            th.esttime-th {
              width: 10% !important;
            }
            th.status-th {
              width: 7% !important;
            }
            th.action-th {
              width: 8% !important;
            }
          }
        }
        tbody {
          tr {
            td {
              padding: 16px 8px !important;
              @media (max-width: 1300px) {
                padding: 16px 12px !important;
              }
            }
          }
          td.status {
            p.draft {
              color: #1f2558;
            }
            p.published {
              color: #007f7f;
            }
          }
        }
      }
    }
  }
}
.brand-list-table {
  thead {
    tr {
      th.id-th {
        width: 10% !important;
      }
      th.img-th {
        width: 20% !important;
      }
      th.name-th {
        width: 25% !important;
      }
      th.url-th {
        width: 35% !important;
      }
      th.active-th {
        width: 10% !important;
      }
      th.empty-th {
        width: 5% !important;
      }
    }
  }
  tbody {
    tr {
      td {
        img {
          max-width: 160px;
          height: auto;
        }
      }
    }
  }
}
.page-content {
  .create-brand-wrap {
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    padding: 24px;
    .btn-grp {
      display: flex;
      justify-content: flex-end;
    }
  }
}
