.content-body {
  .page-content {
    .listing-table.setting-listing-table {
      .table {
        thead {
          tr {
            th {
              padding: 16px !important;
            }
            th.email-template-th {
              width: 55% !important;
            }
            th.email-type-th {
              width: 25% !important;
            }

            th.date-th {
              width: 30% !important;
            }
            th.empty-th {
              width: 10% !important;
            }
          }
        }
        tbody {
          tr {
            td {
              padding: 16px !important;
            }
          }
          td {
            .link-btn {
              font-weight: 500 !important;
              font-size: 14px !important;
              color: #61c2d0 !important;
            }
          }
        }
      }
    }
  }
}
.modal.edit-email-modal {
  .modal-dialog {
    .modal-body {
      text-align: left;
    }
    .modal-footer {
      justify-content: flex-end;
      @media (max-width: 450px) {
        gap: 8px;
      }
    }
  }
}

.setting-page-content {
  .tab-table-wrap {
  }
  .filter-area {
    .table-filter {
      .btn-primary {
        padding: 6px 12px !important;
        height: 48px;
      }
    }
  }
  .listing-table.setting-listing-table {
    position: relative;
    .btn-primary.add-template-btn {
      position: absolute;
      right: 0;
      top: -90px;
      padding: 6px 16px;
      @media (max-width: 767px) {
        border-radius: 14px !important;
        height: 44px !important;
      }
      @media (max-width: 575px) {
        position: unset;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
        gap: 8px;
      }
    }
  }
}
.add-email-template,
.amenity-wrap {
  padding: 24px;
  background-color: #fff;
  border-radius: 20px;
  @media (max-width: 767px) {
    padding: 16px;
  }
  .btn-wrap {
    justify-content: flex-end;
    gap: 16px;
    button {
      min-width: 130px;
    }
  }
  p {
    font-weight: 500;
    font-size: 14px;
  }
  .amenity-list {
    margin-top: 24px;
    .form-check-inline {
      margin-bottom: 24px;
      margin-right: 8px !important;
      label {
        font-weight: normal;
      }
    }
    button.btn.btn-alert {
      padding: 2px 6px;
      background-color: transparent !important;
      color: #4f4f4f !important;
      padding: 0 !important;
      margin: 0;
      display: inline-flex;
      align-items: flex-start;
      border: none !important;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.form-control {
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.add-amenity-modal {
  .modal-body {
    text-align: left !important;
    .multi-select-box {
      > div {
        &:focus {
          border-color: #86b7fe;
          outline: 0;
          box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        }
        > div {
          min-height: 54px;
        }
      }
    }
  }
}
